@mixin vertical-slide--collapsed($duration: $animation-duration-default) {
    max-height: 0;
    overflow: hidden;
    will-change: max-height;
    transition: max-height $duration ease-out;
}

@mixin vertical-slide--expanded($max-height: 99999px) {
    max-height: $max-height;
    transition-timing-function: ease-in;
}

// target only iOS devices
@mixin ios-only {
    @supports (-webkit-overflow-scrolling: touch) {
        @content;
    }
}

// target IE 10+ only
@mixin ie-only {
    /* stylelint-disable */
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        @content;
    }
    /* stylelint-enable */
}

@mixin font-animation-blur-fix {
    -webkit-font-smoothing: subpixel-antialiased;
}

@mixin position-absolute-center($top: 50%, $left: 50%) {
    position: absolute;
    top: $top;
    left: $left;
    transform: translate(-$left, -$top);
}

// targets Safari desktop only
@mixin safari-desktop-only {
    /* stylelint-disable */
    @media not all and (min-resolution:.001dpcm)
    { @supports (-webkit-appearance:none) and (stroke-color:transparent) {
        @content;
    }}
    /* stylelint-enable */
}
