.aob-news-feed {
    padding: $vs-100 20px 0;
    opacity: 0;
    transition: $animation-duration-default;

    @include media('>=medium') {
        padding: $vs-100 34px 0;
    }

    @include media('>=large') {
        padding: $vs-100 40px 0;
    }

    @include media('>=xlarge') {
        padding: $vs-200 94px 0;
    }

    &--revealed {
        opacity: 1;
    }

    &__items {
        @include media('>=medium') {
            display: flex;
        }
    }

    &__item {
        opacity: 0;
        transition: $animation-duration-default;
        transform: translateY(20);

        @include media('>=medium') {
            flex-basis: 33.3%;
            display: flex;
            flex-direction: column;
        }

        @include media('>=large') {
            flex-direction: row;
        }

        &--revealed {
            opacity: 1;
            transform: translateY(0);
        }

        & + & {
            margin-top: $vs-60;

            @include media('>=medium') {
                margin-top: 0;
                margin-left: 20px;
            }

            @include media('>=large') {
                margin-left: 40px;
            }

            @include media('>=xlarge') {
                margin-left: 60px;
            }
        }

        &:nth-child(2) {
            @include media('>=medium') {
                transition-delay: 100ms;
            }
        }

        &:nth-child(3) {
            @include media('>=medium') {
                transition-delay: 200ms;
            }
        }
    }

    &__title {
        margin-bottom: $vs-30;
        font: 900 30px/32px $acumin-pro-condensed;
        letter-spacing: 0.01em;
        color: $cl-prim-light-blue;
        text-transform: uppercase;

        @include media('>=xlarge') {
            font-size: 52px;
            line-height: 57px;
        }
    }

    &__picture,
    &__img {
        width: 100%;
    }

    &__picture {
        display: block;
        margin-bottom: $vs-20;
        overflow: hidden;

        @include media('>=large') {
            flex-basis: calc(100% / 11 * 5);
            margin: 0 20px 0 0;
        }
    }

    &__img {
        transition: transform 2s;
        object-fit: cover;
        background-size: cover;
        background-position: center;

        @include media('>=large') {
            @include ie-only {
                height: 18.75vw;
            }
        }

        &:hover {
            @include media('>=large') {
                transform: scale(1.05);
            }
        }
    }

    &__content {
        @include media('>=medium') {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
        }

        @include media('>=large') {
            flex-basis: calc(100% / 11 * 6);
            justify-content: flex-end;
            align-items: flex-start;

            @include ie-only {
                justify-content: flex-start;
            }
        }
    }

    &__date {
        margin-bottom: 10px;
        font: normal 12px/14px $acumin-pro-condensed;
        letter-spacing: 0.06em;
        color: $cl-prim-grey;

        @include media('>=large') {
            margin: 10px 0 auto;
        }

        @include media('>=xlarge') {
            margin-top: 30px;
            font-size: 18px;
            line-height: 22px;
        }
    }

    &__text {
        margin-bottom: 20px;
        font: 600 16px/23px $acumin-pro-condensed;
        letter-spacing: 0.02em;

        @include media('>=large') {
            margin-bottom: 0;

            @include ie-only {
                max-width: 100%;
            }
        }

        @include media('>=xlarge') {
            font-size: 24px;
            line-height: 30px;
        }
    }

    &__cta {
        font: 500 13px/16px $acumin-pro-condensed;
        letter-spacing: 0.1em;
        color: $cl-sec-white;
        border-bottom-color: $cl-sec-white;

        @include media('>=medium') {
            align-self: flex-start;
            margin: auto auto 0 0;
        }

        @include media('>=large') {
            margin: auto auto 10px 0;
        }

        @include media('>=xlarge') {
            margin-bottom: 30px;
        }

        &:hover,
        &:active,
        &:focus {
            color: $cl-sec-white;
            border-bottom-color: $cl-prim-light-blue;
        }
    }

    &__delimiter {
        border-bottom: 3px solid rgba($cl-sec-white, 0.2);
        padding-top: $vs-60;
    }
}
