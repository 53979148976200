.aob-our-story {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: $cl-sec-black;
    padding: $vs-100 20px 0;

    @include media('>=medium') {
        height: auto;
        align-items: center;
        padding: 130px 34px $vs-100;
    }

    @include media('>=large') {
        padding: 130px 40px $vs-100;
    }

    @include media('>=xlarge') {
        padding: 200px 90px $vs-200;
    }

    &--revealed {
        .aob-our-story {
            &__title,
            &__text,
            &__cta {
                transform: translate3d(0, 0, 0);
                opacity: 1;
            }

            &__text {
                transition-delay: 25ms;
            }

            &__cta {
                transition-delay: 50ms;
            }
        }
    }

    &__title,
    &__text,
    &__cta {
        transform: translate3d(0, 20px, 0);
        opacity: 0;
        transition: opacity 0.3s, transform 0.3s;
    }

    &__title {
        margin-bottom: $vs-30;
        font: 900 30px/32px $acumin-pro-condensed;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        color: $cl-prim-light-blue;

        @include media('>=medium') {
            max-width: 460px;
            text-align: center;
        }

        @include media('>=xlarge') {
            max-width: 720px;
            margin-bottom: $vs-40;
            font-size: 52px;
            line-height: 57px;
        }
    }

    &__text {
        margin-bottom: $vs-30;
        font: normal 12px/17px $acumin-pro;
        letter-spacing: 0.01em;

        @include media('>=medium') {
            max-width: 460px;
            text-align: center;
        }

        @include media('>=xlarge') {
            max-width: 720px;
            margin-bottom: $vs-40;
            font-size: 16px;
            line-height: 25px;
        }
    }

    &__cta {
        min-width: 130px;
        display: inline-block;
        text-align: center;
        font: 500 13px/16px $acumin-pro-condensed;
        letter-spacing: 0.01em;
    }
}
