.aob-sticky-nav {
    display: flex;

    &__left,
    &__right {
        position: relative;
        display: none;
        width: 0;
        overflow: visible;
        z-index: $zi-hp-scroll-nav;

        @include media('>=medium') {
            display: block;
        }
    }

    &__left {
        margin-top: 130vh;

        @include ie-only {
            margin-top: 0;
        }
    }

    &__right {
        left: -34px;

        @include media('>=large') {
            left: -40px;
        }

        @include media('>=xlarge') {
            left: -90px;
        }

        @include ie-only {
            z-index: $zi-page-sticky-nav;
        }
    }

    &__left-sticky,
    &__right-sticky {
        top: 50vh;
        width: 34px;
        transform: translateY(-50%);

        @include media('>=large') {
            width: 40px;
        }

        @include media('>=xlarge') {
            width: 90px;
        }
    }

    &__left-sticky {
        margin-bottom: 17vh;
    }

    &__right-sticky {
        margin-bottom: 10vh;

        @include ie-only {
            margin-bottom: 50vh;
        }
    }

    &__content {
        width: 100%;
        overflow: hidden;
    }
}

.aob-right-sticky {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &__counter,
    &__title {
        position: relative;
        writing-mode: vertical-lr;
        transform: rotate(180deg);
        transform-origin: center;
        text-transform: uppercase;
        font: normal 12px $acumin-pro-condensed;
        letter-spacing: 0.06em;

        @include ie-only {
            transform: rotate(270deg) translateY(50%);
            transform-origin: center bottom;
        }

        @include media('>=xlarge') {
            font-size: 18px;
        }
    }

    &__delimiter {
        flex-grow: 1;
        margin: 20px 0;
        height: 80px;
        width: 0;
        border-left: 1px solid $cl-sec-white;

        @include media('>=xlarge') {
            height: 200px;
        }

        @include ie-only {
            margin: 30px 0;
        }
    }
}

.aob-left-sticky {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: $animation-duration-default;

    &__dot {
        width: 7px;
        height: 7px;
        border: 1px solid $cl-sec-white;
        border-radius: 50%;

        &--active {
            border-color: $cl-prim-light-blue;
            background-color: $cl-prim-light-blue;
        }

        & + & {
            margin-top: $vs-20;
        }
    }
}
