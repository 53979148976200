// BREAKPOINTS
@import '../../config/breakpointsConfig.json';

// construct $breakpoints map with px values from breakpoint definitions in constants/breakpointsConfig.json
$breakpoints: ();

@each $bpName, $bpVal in $breakpoint-defs {
    /* stylelint-disable */
    // px value MUST be present to cast number values to px values
    $breakpoints: map-merge($breakpoints, ('#{$bpName}': to-number($bpVal) + 0px));
    /* stylelint-enable */
}

// COLORS
$black: #000;
$white: #fff;

// primary
$cl-prim-dark-blue: #00101c;
$cl-prim-grey: #6c777a;
$cl-prim-mid-blue: #27828c;
$cl-prim-light-blue: #23ffff;

// secondary
$cl-sec-black: $black;
$cl-sec-white: $white;
$cl-sec-grey: #f2f2f2;

// notification
$cl-notif-error: #eb3300;
$cl-notif-success: #30a951;

// unspecified
$cl-special-1: #e7e7e7;

// VERTICAL SPACINGS
$vs-200: 200px;
$vs-100: 100px;
$vs-90: 90px;
$vs-70: 70px;
$vs-60: 60px;
$vs-50: 50px;
$vs-40: 40px;
$vs-30: 30px;
$vs-20: 20px;
$vs-10: 10px;

// FONTS
$acumin-pro: 'acumin-pro';
$acumin-pro-condensed: 'acumin-pro-condensed';

// ANIMATIONS
// TODO: clarify duration
$animation-duration-default: 300ms;
$easing-default: cubic-bezier(0.25, 0.01, 0.25, 1);

// HEADER
$header-height: (
    small: 75px,
    medium: 95px,
    large: 95px,
    xlarge: 95px
);

// Z-INDEX global list
$zi-header: 100;
$zi-hp-scroll-nav: 10;
$zi-hero-banner: 11;
$zi-page-sticky-nav: 12;
$zi-footer: 12;
$zi-bring-to-front: 1;
$zi-bring-to-back: 1;
