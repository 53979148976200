@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

@keyframes aob-hero-banner__background {
    0% {
        transform: scale(1);
    }

    100% {
        // https://stackoverflow.com/questions/28761962/css3-ken-burns-effects-is-not-smooth-in-ie-11
        transform: scale(1.08) rotate(0.1deg);
    }
}

@keyframes aob-hero-banner__text-line-enter {
    0% {
        transform: translate3d(20px, 0, 0);
        opacity: 0;
        @include font-animation-blur-fix;
    }

    100% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes aob-hero-banner__text-line-exit {
    0% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        @include font-animation-blur-fix;
    }

    100% {
        transform: translate3d(-20px, 0, 0);
        opacity: 0;
    }
}

.aob-hero-banner-slider {
    position: relative;
    background-color: $cl-sec-black;
    overflow: hidden;

    @include ie-only {
        z-index: $zi-hero-banner;
    }

    .slick-slide {
        .aob-banner-slide {
            &__text-line,
            &__cta {
                animation: 0.3s linear 0s 1 aob-hero-banner__text-line-exit;
                animation-fill-mode: forwards;
            }
        }

        &.aob-hero-banner-slider__slide-active {
            .aob-banner-slide {
                &__background-img {
                    animation: 6s linear 0s 1 aob-hero-banner__background;
                    animation-fill-mode: forwards;
                }

                &__text-line,
                &__cta {
                    animation: 0.8s linear 0s 1 aob-hero-banner__text-line-enter;
                    animation-fill-mode: forwards;
                }

                &__text-line-2 {
                    animation-delay: 0.2s;
                }

                &__text-line-3 {
                    animation-delay: 0.4s;
                }

                &__cta {
                    animation-delay: 0.8s;
                }
            }
        }
    }

    &__slider {
        &:not(.slick-initialized) {
            display: flex;

            .aob-banner-slide {
                min-width: 100vw;
            }
        }
    }

    &__bottom {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        padding: 0 20px;

        @include media('>=medium') {
            padding: 0 34px;
        }

        @include media('>=large') {
            flex-direction: row;
            align-items: flex-end;
            padding: 0 40px;
        }

        @include media('>=xlarge') {
            padding: 0 90px;
        }
    }

    &__bottom-nav {
        order: 1;
        overflow: visible !important;

        @include media('>=large') {
            order: 0;
            width: 100%;
        }

        .scroll-content {
            display: flex;
        }

        .scrollbar-track {
            display: none !important;
        }
    }

    &__bottom-nav-delimiter {
        order: 2;
        border-bottom: 3px solid rgba($cl-sec-white, 0.2);

        @include media('>=large') {
            display: none;
        }
    }

    &__bottom-nav-inner {
        width: 100%;
        display: flex;

        @include media('>=medium') {
            justify-content: space-between;
        }
    }

    &__bottom-nav-item {
        margin-bottom: 30px;
        font: normal 12px/14px $acumin-pro-condensed;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        color: $cl-sec-white;
        white-space: nowrap;
        transition: color $animation-duration-default;

        @include media('>=medium') {
            margin-bottom: 20px;
        }

        @include media('>=large') {
            flex-basis: calc(100% / 6);
            margin-bottom: 30px;
            border-top: 3px solid rgba($cl-sec-white, 0.2);
            padding-top: 30px;
            text-align: left;
        }

        @include media('>=xlarge') {
            margin-bottom: 40px;
            font-size: 18px;
            line-height: 22px;
        }

        &:hover {
            @include media('>=large') {
                border-top-color: $cl-prim-light-blue;
                font-weight: bold;
            }
        }

        & + & {
            margin-left: 20px;

            @include media('>=large') {
                margin-left: 5px;
            }

            @include media('>=xlarge') {
                margin-left: 10px;
            }
        }
    }

    &__stock {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 24px;

        @include media('>=medium') {
            max-width: 38%;
            margin-left: auto;
        }

        @include media('>=large') {
            margin-left: 40px;
            margin-bottom: 30px;

            @include ie-only {
                margin-left: 10px;
            }
        }

        @include media('>=xlarge') {
            margin-left: 100px;
            margin-bottom: 40px;

            @include ie-only {
                margin-left: 10px;
            }
        }
    }

    &__stock-date {
        flex-basis: 100%;
        margin-bottom: $vs-10;
        font: normal 12px/14px $acumin-pro-condensed;
        letter-spacing: 0.06em;
        text-transform: uppercase;

        @include media('>=xlarge') {
            font-size: 18px;
            line-height: 22px;
        }
    }

    &__stock-variation {
        width: 70%;
        margin-bottom: 7px;
        white-space: nowrap;
        font: 900 30px/32px $acumin-pro-condensed;
        text-transform: uppercase;
        letter-spacing: 0.01em;

        @include media('>=xlarge') {
            font-size: 52px;
            line-height: 57px;
        }
    }

    &__stock-variation-middot {
        @include ie-only {
            margin-left: 6px;
        }
    }

    &__stock-variation-value {
        display: inline-block;
        border-bottom: 2px solid $cl-sec-white;
        padding-bottom: 5px;
    }

    &__stock-id {
        width: 70%;
        white-space: nowrap;
        font: normal 12px/17px $acumin-pro;
        text-transform: uppercase;

        @include media('>=xlarge') {
            font-size: 14px;
            line-height: 20px;
        }
    }

    &__stock-price {
        max-width: 30%;
        white-space: nowrap;
        margin-bottom: 7px;
        border-bottom: 2px solid $cl-sec-white;
        padding-bottom: 5px;
        font: 900 30px/32px $acumin-pro-condensed;
        letter-spacing: 0.01em;

        @include media('>=xlarge') {
            font-size: 52px;
            line-height: 57px;
        }
    }

    &__stock-currency {
        font: normal 12px/17px $acumin-pro;
        text-transform: uppercase;

        @include media('>=xlarge') {
            font-size: 14px;
            line-height: 20px;
        }
    }

    &__current-slide {
        font: 900 30px/32px $acumin-pro-condensed;
        letter-spacing: 0.01em;

        @include media('>=xlarge') {
            font-size: 52px;
            line-height: 57px;
        }
    }

    &__controls {
        position: absolute;
        top: 310px;
        left: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: $zi-bring-to-front;
        transition: $animation-duration-default;

        @include media('>=medium') {
            top: 530px;
            left: 34px;
        }

        @include media('>=large') {
            top: 255px;
            left: 40px;
        }

        @include media('>=xlarge') {
            top: 325px;
            left: 90px;
        }
    }

    &__controls-progress-text {
        display: flex;
        align-items: stretch;
        margin-bottom: $vs-10;
    }

    &__controls-progress-text-right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 5px 0 0 5px;
    }

    &__ctas {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__controls-of,
    &__slides-total,
    &__slide-prev-cta,
    &__autoplay-switch-cta,
    &__slide-next-cta {
        font: normal 12px/14px $acumin-pro-condensed;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        color: $cl-sec-white;

        @include media('>=xlarge') {
            font-size: 18px;
            line-height: 22px;
        }

        &::before {
            margin: 0;
        }
    }

    &__slide-prev-cta,
    &__slide-next-cta {
        &::before {
            color: $cl-prim-grey;
        }

        &:hover {
            &::before {
                @include media('>=large') {
                    color: $cl-sec-white;
                }
            }
        }
    }

    &__autoplay-switch-cta {
        position: relative;
        width: 20px;
        height: 20px;
        margin: 0 20px;

        @include media('>=large') {
            margin: 0 10px;
        }
    }

    &__controls-progress-bar {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;

        &--play {
            .aob-hero-banner-slider {
                &__controls-progress-bar-play {
                    opacity: 0;
                }

                &__controls-progress-bar-pause {
                    opacity: 1;
                }
            }
        }
    }

    &__controls-progress-bar-play {
        opacity: 1;
        stroke: $cl-sec-white;
        stroke-width: 3px;
        fill: $cl-sec-white;
    }

    &__controls-progress-bar-pause {
        opacity: 0;
        stroke: $cl-sec-white;
        stroke-width: 3px;
    }

    &__progress-bar-flag {
        stroke: rgba($cl-sec-white, 0.5);
        stroke-width: 3px;
        fill: transparent;
        transition: stroke $animation-duration-default;

        &--active {
            stroke: $cl-sec-white;
        }
    }
}

.aob-banner-slide {
    position: relative;
    min-height: 560px;
    height: 100vh;
    box-sizing: border-box;
    background-position: bottom center;
    background-size: auto 100%;
    padding: 115px 20px 0;

    @include media('>=medium') {
        padding: 330px 34px 0;
    }

    @include media('>=large') {
        padding: 250px 40px 0 120px;
    }

    @include media('>=xlarge') {
        padding: 0 90px 0 220px;
        display: flex !important;
        flex-direction: column;
        justify-content: center;
    }

    &__background-img {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: bottom center;
        background-size: cover;
        background-position: bottom center;
    }

    &__text {
        display: flex;
        flex-direction: column;
        margin-bottom: 38px;
        font: 900 70px/61px $acumin-pro-condensed;
        letter-spacing: 1px;
        text-transform: uppercase;
        pointer-events: none;

        @include media('>=large') {
            margin-bottom: $vs-40;
        }

        @include media('>=xlarge') {
            margin-bottom: $vs-30;
            font-size: 160px;
            line-height: 95%;
        }
    }

    &__text-line {
        transform: translate3d(20px, 0, 0);
        opacity: 0;
    }

    &__cta {
        position: absolute;
        right: 20px;
        transform: translate3d(20px, 0, 0);
        display: inline-block;
        border-bottom: 1px solid $cl-sec-white;
        padding-bottom: 10px;
        opacity: 0;
        font: 500 13px/16px $acumin-pro-condensed;
        letter-spacing: 0.1em;
        color: $cl-sec-white;
        text-transform: uppercase;

        @include ie-only {
            align-self: flex-start;
        }

        @include media('>=medium') {
            right: 34px;
        }

        @include media('>=large') {
            position: static;
        }

        @include media('>=xlarge') {
            margin-right: auto;
        }

        &:hover {
            border-bottom-color: $cl-prim-light-blue;
        }
    }
}
