.aob-annual-report {
    position: relative;
    padding: $vs-60 0 50px;
    opacity: 0;
    transition: $animation-duration-default;

    @include media('>=medium') {
        min-height: calc((100vw - 34px * 2) / 700 * 335);
        margin: 140px 34px 50px;
    }

    @include media('>=large') {
        min-height: calc((100vw - 40px * 2) / 950 * 450);
        margin: 140px 40px 50px;
    }

    @include media('>=xlarge') {
        min-height: calc((100vw - 90px * 2) / 1500 * 720);
        margin: 240px 90px 50px;
    }

    &--revealed {
        opacity: 1;

        .aob-annual-report {
            &__title,
            &__text,
            &__cta {
                @include media('>=medium') {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }
    }

    &__picture,
    &__img {
        width: 100%;

        @include media('>=medium') {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }

    &__picture {
        overflow: hidden;
    }

    &__img {
        background-size: cover;
        background-position: center;

        @include media('>=medium') {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__content {
        position: relative;
        margin-top: -65px;
        padding: 0 20px;

        @include media('>=medium') {
            transform: translateY(-45px);
        }

        @include media('>=large') {
            transform: translateY(-40px);
        }

        &--revealed {
            .aob-annual-report {
                &__title,
                &__text,
                &__cta {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }
    }

    &__title,
    &__text,
    &__cta {
        opacity: 0;
        transform: translateY(20px);
        transition: $animation-duration-default;

        @include media('>=medium') {
            max-width: 280px;
        }

        @include media('>=xlarge') {
            max-width: 490px;
        }
    }

    &__title {
        margin-bottom: $vs-30;
        font: 900 46px/42px $acumin-pro-condensed;
        text-transform: uppercase;

        @include media('>=xlarge') {
            font-size: 80px;
            line-height: 103%;
        }
    }

    &__text {
        margin-bottom: $vs-30;
        font: 600 16px/23px $acumin-pro-condensed;
        letter-spacing: 0.02em;
        transition-delay: 100ms;

        @include media('>=xlarge') {
            font-size: 24px;
            line-height: 30px;
        }
    }

    &__cta {
        min-width: 140px;
        text-align: center;
        font: 500 13px/16px $acumin-pro-condensed;
        letter-spacing: 0.1em;
        transition-delay: 200ms;
    }
}
