.aob-investors {
    position: relative;
    height: 100vh;
    padding: 45px 20px $vs-100;
    overflow: hidden;

    @include media('>=medium') {
        padding: 0 200px $vs-100;
    }

    @include media('>=large') {
        display: flex;
        align-items: flex-end;
        padding: 0 120px 70px;
    }

    @include media('>=xlarge') {
        padding: 0 220px $vs-100;
    }

    &--revealed {
        .aob-investors {
            &__stock {
                @include media('>=large') {
                    opacity: 1;
                }
            }

            &__title,
            &__text,
            &__cta {
                @include media('>=large') {
                    opacity: 1;
                }
            }
        }
    }

    &__background-picture,
    &__background-img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    &__background-img {
        background-size: cover;
        background-position: center;
    }

    &__content {
        position: relative;
        width: 100%;
        height: 100%;

        @include media('>=medium') {
            display: flex;
            flex-direction: column;
        }

        @include media('>=large') {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            height: auto;
        }
    }

    &__main {
        @include media('>=medium') {
            margin-top: auto;
        }

        @include media('>=large') {
            margin-top: 0;
            width: 370px;
        }

        @include media('>=xlarge') {
            width: 460px;
        }
    }

    &__title,
    &__text,
    &__cta {
        @include media('>=large') {
            opacity: 0;
            transition: opacity $animation-duration-default;
        }
    }

    &__title {
        margin-bottom: $vs-30;
        font: 900 46px/42px $acumin-pro-condensed;
        text-transform: uppercase;

        @include media('>=xlarge') {
            font-size: 80px;
            line-height: 103%;
        }
    }

    &__text {
        margin-bottom: $vs-30;
        font: normal 12px/17px $acumin-pro;

        @include media('>=large') {
            transition-delay: 40ms;
        }

        @include media('>=xlarge') {
            font-size: 16px;
            line-height: 25px;
        }
    }

    &__cta {
        min-width: 140px;
        margin-bottom: $vs-60;
        text-align: center;
        font: 500 13px/16px $acumin-pro-condensed;
        text-transform: uppercase;
        letter-spacing: 0.1em;

        @include media('>=large') {
            margin-bottom: 0;
            transition-delay: 80ms;
        }
    }

    &__stock {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        flex-wrap: wrap;

        @include media('>=large') {
            width: 290px;
            opacity: 0;
            transition: opacity $animation-duration-default;
        }

        @include media('>=xlarge') {
            width: 590px;
        }
    }

    &__stock-graph {
        flex-basis: 100%;
        visibility: hidden;
    }

    &__stock-graph-picture {
        width: 100%;
    }

    &__stock-id {
        font: 900 16px/19px $acumin-pro-condensed;
        letter-spacing: 0.06em;
        text-transform: uppercase;

        @include media('>=xlarge') {
            font-size: 22px;
            line-height: 26px;
        }
    }

    &__date {
        font: normal 12px/14px $acumin-pro-condensed;
        letter-spacing: 0.06em;
        text-transform: uppercase;

        @include media('>=xlarge') {
            font-size: 18px;
            line-height: 22px;
        }
    }

    &__stock-price {
        font: 900 30px/32px $acumin-pro-condensed;
        letter-spacing: 0.01em;
        text-transform: uppercase;
    }

    &__stock-variation {
        font: normal 12px/14px $acumin-pro-condensed;
        letter-spacing: 0.06em;

        &--currency {
            margin-left: auto;
        }
    }
}
