.aob-careers {
    padding: 0 20px $vs-100;
    background-color: $cl-sec-black;
    opacity: 0;
    color: $cl-sec-white;
    transition: opacity $animation-duration-default;

    @include media('>=medium') {
        padding: 0 34px $vs-100;
    }

    @include media('>=large') {
        padding: 0 40px $vs-100;
    }

    @include media('>=xlarge') {
        padding: 0 90px $vs-200;
    }

    &--revealed {
        opacity: 1;

        .aob-careers {
            &__main-background {
                @include media('>=medium') {
                    opacity: 1;
                }
            }

            &__roles {
                @include media('>=medium') {
                    opacity: 1;
                }
            }
        }
    }

    &__main {
        min-height: 100vh;
        margin-top: -32px;
        margin-bottom: $vs-60;

        @include media('>=medium') {
            position: relative;
            min-height: auto;
            margin-top: 0;
            padding-top: 32px;
        }

        @include media('>=xlarge') {
            margin-top: -25px;
        }
    }

    &__main-background {
        margin-bottom: -63px;
        margin-left: -20px;
        width: calc(100% + 20px);
        height: 100vw;
        object-fit: cover;
        object-position: center;
        background-size: cover;
        background-position: center;
        opacity: 0.75;

        @include media('>=medium') {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin-bottom: 0;
            margin-left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: opacity $animation-duration-default 200ms;
        }
    }

    &__content {
        position: relative;

        &--revealed {
            .aob-careers {
                &__title,
                &__text,
                &__cta {
                    opacity: 1;
                    top: 0;
                }
            }
        }

        @include media('>=medium') {
            // aspect ratio 2:1, without wrapper paddings
            height: calc((100vw - 34px * 2) / 2 + 32px);
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            margin-top: -76px;
            padding: 0 $vs-60 $vs-40;
            z-index: $zi-bring-to-front;
        }

        @include media('>=large') {
            height: calc((100vw - 40px * 2) / 2 + 32px);
            padding: 0 80px 80px;
        }

        @include media('>=xlarge') {
            height: calc((100vw - 90px * 2) / 2 + 25px);
            padding: 0 130px 115px;
        }
    }

    &__title,
    &__text,
    &__cta {
        position: relative;
        opacity: 0;
        top: 20px;
        transition: $animation-duration-default;
    }

    &__title {
        position: relative;
        margin-bottom: $vs-30;
        font: 900 46px/42px $acumin-pro-condensed;
        text-transform: uppercase;

        @include media('>=medium') {
            flex-grow: 1;
            width: 50%;
            height: 100%;
            margin-bottom: 0;
            padding: 0 10px;
        }

        @include media('>=xlarge') {
            font-size: 80px;
            line-height: 103%;
        }
    }

    &__text {
        margin-bottom: $vs-30;
        font: normal 12px/17px $acumin-pro;
        transition-delay: 40ms;

        @include media('>=medium') {
            margin-top: auto;
            margin-left: auto;
            padding: 0 10px;
            width: 50%;
        }

        @include media('>=xlarge') {
            font-size: 16px;
            line-height: 25px;
        }
    }

    &__cta {
        min-width: 130px;
        text-align: center;
        font: 500 13px/16px $acumin-pro-condensed;
        letter-spacing: 0.1em;
        transition-delay: 80ms;

        @include media('>=medium') {
            align-self: flex-start;
            margin-left: 10px;
        }
    }

    &__roles {
        @include media('>=medium') {
            display: flex;
            opacity: 0;
            transition: opacity $animation-duration-default 200ms;
        }
    }

    &__role {
        border-bottom: 3px solid $cl-prim-mid-blue;

        @include media('>=medium') {
            flex-basis: 25%;
            flex-grow: 1;
        }

        &:hover {
            @include media('>=large') {
                border-bottom-color: $cl-prim-light-blue;
            }
        }

        & + & {
            margin-top: $vs-30;

            @include media('>=medium') {
                margin: 0 0 0 20px;
            }
        }
    }

    &__role-anchor {
        color: $cl-sec-white;
    }

    &__role-count {
        margin-bottom: $vs-10;
        font: normal 12px/14px $acumin-pro-condensed;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        color: $cl-prim-grey;

        @include media('>=xlarge') {
            font-size: 18px;
            line-height: 22px;
        }
    }

    &__role-text {
        margin-bottom: $vs-10;
        font: 600 16px/23px $acumin-pro-condensed;
        letter-spacing: 0.02em;

        @include media('>=large') {
            margin-bottom: $vs-20;
        }

        @include media('>=xlarge') {
            font-size: 24px;
            line-height: 30px;
        }
    }
}
